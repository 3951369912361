import React, { useEffect, useContext, useRef, useState, Fragment } from "react";
import { GlobalStateContext } from "../../context/GlobalContextProvider";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../../components/seo/seo";
import Layout from "../../components/layout/layout";
import PageBanner from "../../components/common/PageBanner";
import ImagePreview from '../../components/common/ImagePreview';
import Sidebar from '../../components/common/ArticleSidebar'
import SocialMedia from '../../components/common/SocialMedia'
import empty from '../../assets/img/icons/empty.png'
import ogImg from '../../assets/img/pages/newsletter/simulator/Main-img.jpg'
import ulImg1 from '../../assets/img/icons/simulator/1-new-learning-method.svg'
import ulImg2 from '../../assets/img/icons/simulator/2-realistic-vehicle-movements.svg'
import ulImg3 from '../../assets/img/icons/simulator/3-Realistic-3D-desert-environment.svg'
import ulImg4 from '../../assets/img/icons/simulator/4-Big-killer-hazards-covered.svg'
import ulImg5 from '../../assets/img/icons/simulator/5-Learning-through-trying.svg'
import ulImg6 from '../../assets/img/icons/simulator/6-Mistakes-do-not-lead-to-real-accidents.svg'
import ulImg7 from '../../assets/img/icons/simulator/7-All levels-of-learning-addressed.svg'
import ulImg8 from '../../assets/img/icons/simulator/8-Engages-all-senses-used-by-drivers.svg'
import ulImg9 from '../../assets/img/icons/simulator/9-Risk-free-On-the-Road-learning.svg'
import ulImg10 from '../../assets/img/icons/simulator/10-Objective-driver-assessment.svg'
import ulImg11 from '../../assets/img/icons/simulator/11-Objective-recording-of-driver-behaviour.svg'
import ulImg12 from '../../assets/img/icons/simulator/12-Complete-support-service-available.svg'
import ulImg13 from '../../assets/img/icons/simulator/13-Hardware-and-software-one-vendor.svg'
import ulImg14 from '../../assets/img/icons/simulator/14-Integrated-with-driver-learning-database.svg'
import phoneImg from '../../assets/img/icons/call-icon.svg'
import emailImg from '../../assets/img/icons/email-icon.svg'


const VirtualRealityDrivingSimulator = ({ location }) => {
  const [pageTitle] = useState('Virtual Reality Driving Simulator')
  const state = useContext(GlobalStateContext);
  let container = useRef(null);
  const slug = useState(location.pathname.split('/')[2]);
  let endDiv = useRef(null);
  const [endDivPosition, setEndDivPosition] = useState(0);

  const [showImgPrev, setShowImgPrev] = useState(false)
  const [activeImg, setActiveImg] = useState(empty)

  const handeleActiveImg = (img) => {
    setActiveImg(img)
    setShowImgPrev(true)
  }

  const updatePosition = () => {
    setEndDivPosition(endDiv.current.getBoundingClientRect().top)
  };

  useEffect(() => {
    window.addEventListener("scroll", updatePosition);

    return () => window.removeEventListener("scroll", updatePosition);
  }, [endDivPosition]);

  useEffect(() => {
    if (!state.showPageLoading) {
      const cn = container.current;
      setTimeout(() => {
        cn.classList.remove("hide-container");
      }, state.containerAnimationDelay * 1000);
    }
  }, [state.showPageLoading, state.containerAnimationDelay]);

  return (
    <Fragment>
      <ImagePreview img={activeImg} orientation="landscape" onClick={() => setShowImgPrev(!showImgPrev)} show={showImgPrev} />
      <Layout>
        <Seo
          title={pageTitle}
          description='Good Technology Creates Great Safety. Contact us now!'
          image={ogImg}
          ogType='article'
        />
        <PageBanner title='Virtual Reality Driving Simulator' useDiv={true} />
        <section
          className='main-container single-article hide-container'
          ref={container}
        >
          <div className='row less-mar bottom-pad'>
            <SocialMedia
              pageUrl={location.href}
              pageTitle={pageTitle}
              endDivPosition={endDivPosition}
            />
            {/* Article */}
            <article className='column article-details driving-simulator'>
              <header>
                <h1 className="main-title small primary-color">
                  <strong>Virtual Reality Driving Simulator</strong>
                </h1>
                <div className='author-date'>
                  5 minutes read
                </div>
              </header>
              <video muted="1" controls="1" autoPlay width="100%" height="400px">
                <source src="https://secure.virsat.com/video/Simulator.mp4" type="video/mp4"/>
                Your browser does not support the video tag.
              </video>
              

              <h2 className="primary-color" style={{ "marginTop": "20px" }}>Good Technology Creates Great Safety</h2>
              <p className="justify">
                A century ago, the Wright Brothers developed the first plane. It was not very safe at first.
                Throughout the world clever engineers developed solutions for making planes safer. Key
                component of that was to train the pilots. Very sophisticated flight simulators are essential
                in pilot training now. From its very unsafe start, flying is now often the safest mode of
                transport.
              </p>

              <p> In road safety we see the same developments, many features make the vehicle safer in
                case of incidents. Some new features help to avoid accidents. However, almost always the
                driver remains essential in preventing incidents. Drivers have a major responsibility for
                people and equipment. We trust our lives in the driver's hands when we are a passenger.</p>
              <p>
                For decades driver training has played an important role in preventing incidents. Current
                “on the road” driver training is essential for road safety. Recently the VIRSAT VR driving
                games were added to complement this road training. With these immersive training
                method drivers learn to fully understand and recall essential road safety rules and know
                when and where to apply them.
              </p>

              <div className="flex airplane">
                <div
                  aria-hidden>
                  <StaticImage
                    src='../../assets/img/pages/newsletter/simulator/airplane-simulator1.jpg'
                    alt='Airplane Simulator 1'
                    title='Airplane Simulator 1'
                    quality="100"
                    objectFit
                    className='gatsby-image'
                  />
                </div>
                <div className="middle-column" />
                <div
                  aria-hidden>
                  <StaticImage
                    src='../../assets/img/pages/newsletter/simulator/airplane-simulator2.jpg'
                    alt='Airplane Simulator 2'
                    title='Airplane Simulator 1'
                    quality="100"
                    objectFit
                    className='gatsby-image'
                  />
                </div>
              </div>

              <h2 className="primary-color" style={{ "marginTop": "20px" }}>Graded Road Driving Simulator<sup>®</sup></h2>

              <div className="flex graded-road">
                <div>
                  <p>
                    The last training step is from knowing and doing. to understanding On-the-road training
                    remains important for that. However, it is also
                    risky, if the training is realistic, and expensive. VIRSAT introduces the
                    world's first driving simulator for to complement roads graded on-the-road training. With the Graded
                    Road Driving Simulator<sup>®</sup> drivers can drive through dust, being in a pre-rollover situation and 10 other
                    situations where many incidents happen. This is done in a perfectly
                    safe yet very realistic environment.
                  </p>
                </div>
                <div>
                  <div
                    aria-hidden>
                    <StaticImage
                      src='../../assets/img/pages/newsletter/simulator/driving-simulator.jpg'
                      alt='Driving Simulator'
                      title='Driving Simulator'
                      quality="100"
                      objectFit
                      className='gatsby-image'
                    />
                  </div>
                </div>
              </div>

              <p>VIRSAT Graded Road Driving Simulator<sup>®</sup> brings graded road driver training to a new level of 
              sophistication and effectiveness. What flight simulators do for flight safety is now feasible 
              and affordable for driver training with the VIRSAT Graded Road Driving Simulator<sup>®</sup>.</p>

              <h2 className="primary-color" style={{ "marginTop": "20px" }}>How does it work?</h2>

              <div className="flex graded-road-1">
                <div>
                  <p>
                  The driver sees the road ahead in 3D and needs to react to realistic situations 
                  on the road. He has all vehicle controls and can feel the bumps in the road. Our 
                  sophisticated VR headset and 6 Degrees of Freedom simulator makes all this very 
                  realistic. Even accidents can “happen”.
                  </p>
                </div>
                <div>
                  <div
                    aria-hidden>
                    <StaticImage
                      src='../../assets/img/pages/newsletter/simulator/6DoF.jpg'
                      alt='6DoF'
                      title='6DoF'
                      quality="100"
                      objectFit
                      className='gatsby-image'
                    />
                  </div>
                </div>
              </div>

              <p>The VIRSAT Graded Road Driving® simulator lets drivers respond to the following hazards.</p>

              <ul className="ul-table bg-primary">
                <li>Driving through dust</li>
                <li>Fatigue</li>
                <li>Driving too close to the vehicle in front</li>
                <li>Not switching on head lights</li>
                <li>Driving too fast for road conditions</li>
                <li>Not switching on high intensity rear lights</li>
                <li>Roll over prevention</li>
                <li>Not seeking contact when lost</li>
                <li>Overtaking without sufficient visibility</li>
                <li>Dealing with a tire blowout</li>
              </ul>

              <p className="mar-bottom">
                During a 25 minutes drive the trainee experiences all these hazards. After that they get 
                feedback on how they did. Even experienced drivers discover areas for improvement when 
                training on the VIRSAT Graded Road Driving<sup>®</sup>.
              </p>

              <div
                aria-hidden>
                <StaticImage
                  src='../../assets/img/pages/newsletter/simulator/virsat-driving-simulator-game.jpg'
                  alt='Virsat Driving Simulator Game'
                  title='Virsat Driving Simulator Game'
                  quality="100"
                  objectFit
                  className='gatsby-image'
                />
              </div>

              <table>
                <thead>
                  <tr>
                    <th colSpan={2}>VEHICLES COVERED</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="w-50">4WD Toyota Land Cruiser</td>
                    <td>Heavy Vehicle</td>
                  </tr>
                </tbody>
              </table>

              <table>
                <thead>
                  <tr>
                    <th>PLANNED</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Ambulance</td>
                  </tr>
                  <tr>
                    <td>Fire Truck for Integration with Fire Response Software</td>
                  </tr>
                  <tr>
                    <td>Tipper Truck</td>
                  </tr>
                </tbody>
              </table>

              <div className="flex full-width ul-icons-list">
                <div className="w-50">
                  <h2 className="primary-color">Innovative Learning</h2>
                  <ul className="ul-icons">
                    <li>
                      <img src={ulImg1} alt="New learning method"/>
                      <span>New learning method</span>
                    </li>
                    <li>
                      <img src={ulImg2} alt="Realistic vehicle movements"/>
                      <span>Realistic vehicle movements</span>
                    </li>
                    <li>
                      <img src={ulImg3} alt="Realistic 3D desert environment"/>
                      <span>Realistic 3D desert environment</span>
                    </li>
                    <li>
                      <img src={ulImg4} alt="Big-killer hazards covered"/>
                      <span>Big-killer hazards covered</span>
                    </li>
                  </ul>
                </div>
                <div>
                  <h2 className="primary-color">What's New?</h2>
                  <ul className="ul-icons">
                    <li>
                      <img src={ulImg5} alt="Learning through trying"/>
                      <span>Learning through trying</span>
                    </li>
                    <li>
                      <img src={ulImg6} alt="Mistakes do not lead to real accidents"/>
                      <span>Mistakes do not lead to real accidents</span>
                    </li>
                    <li>
                      <img src={ulImg7} alt="All levels of learning addressed"/>
                      <span>All levels of learning addressed</span>
                    </li>
                    <li>
                      <img src={ulImg8} alt=" Engages all senses used by drivers"/>
                      <span> Engages all senses used by drivers</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="flex full-width ul-icons-list">
                <div className="w-50">
                <h2 className="primary-color">What is Different?</h2>
                  <ul className="ul-icons">
                    <li>
                      <img src={ulImg9} alt=" Risk-free “On the Road” learning"/>
                      <span>Risk-free “On the Road” learning</span>
                    </li>
                    <li>
                      <img src={ulImg10} alt=" Objective driver assessment"/>
                      <span>Objective driver assessment</span>
                    </li>
                    <li>
                      <img src={ulImg11} alt="Objective recording of driver behaviour"/>
                      <span>Objective recording of driver behaviour</span>
                    </li>
                  </ul>
                </div>
                <div>
                  <h2 className="primary-color">Care-Free</h2>
                  <ul className="ul-icons">
                    <li>
                      <img src={ulImg12} alt=" Complete support service available"/>
                      <span> Complete support service available</span>
                    </li>
                    <li>
                      <img src={ulImg13} alt="Hardware and software, one vendor"/>
                      <span>Hardware and software, one vendor</span>
                    </li>
                    <li>
                      <img src={ulImg14} alt="AIntegrated with driver learning database"/>
                      <span>AIntegrated with driver learning database</span>
                    </li>
                  </ul>
                </div>
              </div>

              <br/>
              <div
                onClick={() => handeleActiveImg(ogImg)}
                aria-hidden className='article-main-image'>
                <StaticImage
                  src='../../assets/img/pages/newsletter/simulator/Main-img.jpg'
                  alt='Virtual Reality Driving Simulator'
                  title='Virtual Reality Driving Simulator'
                  quality="100"
                  objectFit
                  className='gatsby-image'
                />
              </div>

              <h2 className="primary-color" style={{ "marginTop": "30px" }}>Demonstration</h2>
              <p> The VIRSAT Graded Road Driving® simulator can be leased or bought. We have a range of  commercial offers that  can help large and smaller businesses taking the next step in driver training.  VIRSAT or one of our local partners can arrange for a demonstration.</p>
              
              <div className={`driving-simulator`}>
                <p className='center no-mar'>
                  We are happy to provide information tailored to your company's need.
                </p>
                <p> Please call us or send an email.</p>
                <ul>
                  <li>
                    <div>
                      <img src={phoneImg} alt="Phone"/>
                    </div>
                    <a href="tel:+97126436640">+971 2 643 6640</a>
                  </li>
                  <li>
                    <div>
                      <img src={emailImg} alt="Email"/>
                    </div>
                    <a href="mailto:info@virsat.com">info@virsat.com</a>
                  </li>
                </ul>
              </div>

              <div style={{ height: '1px', 'width': '1px' }} ref={endDiv} />
            </article>
            {/* End: Article */}

            {/* Sidebar */}
            <Sidebar slug={slug} endDivPosition={endDivPosition} type="news" stickyCta="email_phone" />
          </div>
        </section>
      </Layout>
    </Fragment>
  )
}

export default VirtualRealityDrivingSimulator